// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import HelloWorld from "@/components/HelloWorld.vue";
import Home from "@/components/Home.vue";
import TermAndPolicy from "@/components/TermAndPolicy.vue";

const routes = [
    { path: '/', component: HelloWorld },
    { path: '/home', component: Home },
    { path: '/terms', component: TermAndPolicy }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
