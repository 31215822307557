<template>
  <div>
    <iframe src="https://boostbox-nots-inc.com" style="width: 100%; height: 80vh;"></iframe>
    <footer>
      <a href="/terms">Terms of Service and Privacy Policy</a>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'HomePage',
};
</script>

<style>
footer {
  text-align: center;
  padding: 20px;
}
</style>
