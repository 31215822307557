<template>
  <div class="hello">
    <div v-if="auth">
      <div>
        <img :src="imageUrl"  alt="" class="image-url"/>
      </div>
      <div class="main-container">
        <select v-model="selectedOption">
            <option value="tiktok">Tiktok</option>
            <option value="youtube">YouTube</option>
        </select>
        <input placeholder="アカウント名" v-model="userName" v-if="selectedOption === 'youtube'"/>
        <textarea placeholder="動画URLをカンマ区切りで入力してください" v-model="userName" v-if="selectedOption === 'tiktok'"/>
        <button @click="getInfo">検索</button>
        <button @click="download">CSVダウンロード</button>
      </div>
      <div v-if="loading">
        <h2>情報取得中・・・</h2>
        <h2>最大1分程かかります</h2>
        <p>しばらく経っても画面が変わらない場合は予期せぬエラーが起きています</p>
      </div>
      <div v-else>
        <div v-if="videoInfo === null && !loading">
          <h2>表示する内容はありません</h2>
        </div>
        <div v-else>
          <h3>アカウント情報</h3>
          <table>
            <tbody>
            <tr>
              <td v-if="selectedOption === 'tiktok'">総合フォロワー</td>
              <td v-if="selectedOption === 'tiktok'">総合ファボ</td>
              <td>出力日時</td>
            </tr>
            </tbody>
            <tbody>
            <tr>
              <td v-if="selectedOption === 'tiktok'">{{ totalFollower }}</td>
              <td v-if="selectedOption === 'tiktok'">{{ totalFav }}</td>
              <td>{{ date }}</td>
            </tr>
            </tbody>
          </table>
          <h3>動画情報</h3>
          <table>
            <tbody>
            </tbody>
            <tbody>
            <tr>
              <td v-if="selectedOption === 'youtube'">投稿日</td>
              <td>再生回数</td>
              <td v-if="selectedOption === 'tiktok'">fav数</td>
              <td v-else>いいね数</td>
              <td>コメント数</td>
              <td v-if="selectedOption === 'tiktok'">シェア数</td>
              <td v-if="selectedOption === 'youtube'">動画リンク</td>
              <td v-if="selectedOption === 'tiktok'">ブックマーク</td>
            </tr>
            </tbody>
            <tbody v-for="(info, index) in videoInfo" :key="index">
            <tr>
              <td v-if="selectedOption === 'youtube'">{{ info.publishedAt }}</td>
              <td>{{ info.playCount }}</td>
              <td>{{ info.fav }}</td>
              <td>{{ info.comment }}</td>
              <td v-if="selectedOption === 'tiktok'">{{ info.share }}</td>
              <td v-if="selectedOption === 'youtube'">{{ info.link }}</td>
              <td v-if="selectedOption === 'tiktok'">{{ info.collect }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div v-else>
      <input v-model="username" type="text" placeholder="Username">
      <input v-model="password" type="password" placeholder="Password">
      <button @click="login">Login</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  data() {
    return {
      userName: "",
      totalFollower: null,
      totalFav: null,
      videoInfo: null,
      date: null,
      loading: false,
      selectedOption: 'tiktok',
      imageUrl: require('@/assets/confidential.jpg'),
      auth: false,
      username: '',
      password: '',
    }
  },
  methods: {
    async getInfo() {
      if (this.userName === "") {
        alert("アカウント名が入力されていません")
        return
      }
      // if (this.userName.match(/@/) && this.selectedOption === 'tiktok') {
      //   alert("アカウント名から@を抜いてください")
      //   return
      // }
      if (!this.userName.includes("https://www.youtube.com") && this.selectedOption === 'youtube') {
          alert("YouTubeはURLで入力してください")
          return
      }
      if ((this.userName.includes("shorts") || this.userName.includes("featured") || this.userName.includes("videos")) && this.selectedOption === 'youtube') {
          const splitUrl = this.userName.split("/")
          splitUrl.pop()
          const newUrl = splitUrl.join("/")
          this.userName = newUrl
      }
      this.loading = true
      if (this.selectedOption === "tiktok") {
          const response = await fetch(`https://tiktok-scrape.onrender.com/info?name=${this.userName}`, {})
          const tiktokInfo = await Promise.all([response.json()])
          console.log(tiktokInfo)
          this.totalFollower = this.formatInfo(tiktokInfo[0].total_follower)
          this.totalFav = this.formatInfo(tiktokInfo[0].total_fav)
          const playCounts = tiktokInfo[0].play_counts
          const favs = tiktokInfo[0].video_favs
          const comments = tiktokInfo[0].video_comment_counts
          const shares = tiktokInfo[0].video_share_counts || 0
          const collects = tiktokInfo[0].video_collect_counts || 0
          this.videoInfo = playCounts.map((d, index) => {
              return {
                  playCount: this.formatInfo(d),
                  fav: this.formatInfo(favs[index]),
                  comment: this.formatInfo(comments[index]),
                  share: this.formatInfo(shares[index]),
                  collect: this.formatInfo(collects[index]),
              }
          })
      }
      if (this.selectedOption === "youtube") {
          const response = await fetch(`https://tiktok-scrape.onrender.com/youtube?name=${this.userName}`, {})
          const youtubeInfo = await Promise.all([response.json()])
          console.log(youtubeInfo)
          if (!youtubeInfo[0].view_counts || !youtubeInfo[0].like_counts) {
              alert("正しく情報が取得出来ませんでした。入力内容に誤りが無いか確認してください")
              return
          }
          const playCounts = youtubeInfo[0].view_counts
          const likes = youtubeInfo[0].like_counts
          const urls = youtubeInfo[0].video_urls
          const comments = youtubeInfo[0].comment_counts
          const publishedAt = youtubeInfo[0].published_at
          const jpPublishedAt = publishedAt.map(date => this.formatDate(date))
          this.videoInfo = playCounts.map((d, index) => {
              return {
                  playCount: d,
                  fav: likes[index],
                  link: urls[index],
                  comment: comments[index],
                  publishedAt: jpPublishedAt[index]
              }
          })
      }
      this.date = new Date().toLocaleString({ timeZone: 'Asia/Tokyo' })
      this.loading = false
    },
    formatDate(date) {
      const utcDate = new Date(date);
      utcDate.setHours(utcDate.getHours() + 9);
      return utcDate.toLocaleString('ja-JP', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        hour12: false
      }).replace(/\//g, '-')
    },
    formatInfo(data) {
      if (data.match(/K/) && data.includes('.')) {
        data = data.replace('K', '00')
      }
      if (data.match(/K/)) {
        data = data.replace('K', '000')
      }
      if (data.match(/M/) && data.includes('.')) {
        data = data.replace('M', '00000')
      }
      if (data.match(/M/)) {
        data = data.replace('M', '000000')
      }
      return data.replace('.', '')
    },
    download() {
      if (this.selectedOption === "tiktok") {
        let videoInfoArray = this.videoInfo.map(info => [info.playCount, info.fav, info.comment, info.share, info.collect]);
        videoInfoArray.unshift(['再生回数', 'fav数', 'コメント数', 'シェア数', "ブックマーク数"]);
        let transposedArray = videoInfoArray[0].map((_, i) => videoInfoArray.map(row => row[i]));
        let csvContent = transposedArray.map(row => row.join(',')).join('\n');
        csvContent = `総合フォロワー,${this.totalFollower}\n総合fav数,${this.totalFav}\n` + csvContent;
        const blob = new Blob([csvContent], {type: "text/csv"}); //配列に上記の文字列(str)を設定
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download ="tiktok.csv";
        link.click();
      }
        if (this.selectedOption === "youtube") {
          let videoInfoArray = this.videoInfo.map(info => [info.playCount, info.fav, info.comment, info.link]);
          videoInfoArray.unshift(['再生回数', 'いいね数', 'コメント数', 'リンク']);
          let transposedArray = videoInfoArray[0].map((_, i) => videoInfoArray.map(row => row[i]));
          let csvContent = transposedArray.map(row => row.join(',')).join('\n');
          const blob = new Blob([csvContent], {type: "text/csv"});
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download ="tiktok.csv";
          link.click();
        }
    },
    login() {
      const fixedUsername = 'nots.inc'; // 定めたユーザー名
      const fixedPassword = 'nots2020'; // 定めたパスワード

      if(this.username === fixedUsername && this.password === fixedPassword){
        const now = new Date();
        const expiryDate = now.getTime() + 24 * 60 * 60 * 1000; // 24時間後

        localStorage.setItem('auth', true);
        localStorage.setItem('expiryDate', expiryDate);

        this.auth = true;
      } else {
        alert('Username or password is incorrect.');
        this.username = '';
        this.password = '';
      }
    },
    checkAuthStatus() {
      const auth = localStorage.getItem('auth');
      const expiryDate = localStorage.getItem('expiryDate');

      if (!auth || !expiryDate) {
        return;
      }

      const now = new Date();
      if (now.getTime() > expiryDate) {
        localStorage.removeItem('auth');
        localStorage.removeItem('expiryDate');
      } else {
        this.auth = true;
      }
    },
  },
  created() {
    this.checkAuthStatus();
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.image-url {
  max-width: 200px;
  width: 100%;
}
.main-container {
  display: flex;
  align-items: flex-start;
  margin: auto;
  width: 470px;
}
table{
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

table tr{
  border-bottom: solid 1px #eee;
  cursor: pointer;
}

table tr:hover{
  background-color: #d4f0fd;
}

table th,table td{
  text-align: center;
  width: 25%;
  padding: 15px 0;
}

button{
  margin: 0 10px;
}
input{
  margin: 0 10px;
  height: 20px;
}

textarea{
  margin: 0 10px;
}
</style>
