<template>
  <div class="terms-policy-container">
    <h2>Terms of Service</h2>
    <h3>1. Overview</h3>
    <p>This application provides a customized video aggregation service to enhance user experience by presenting videos in a personalized manner. By using our service, you agree to these terms. Please read them carefully.</p>

    <h3>2. User Responsibility</h3>
    <p>Users are responsible for any activity that occurs under their account and must respect the copyrights and privacy of others.</p>

    <h3>3. Intellectual Property Rights</h3>
    <p>All rights in the original content provided by the service are owned by the application or its licensors. No content may be used without express permission from the copyright owner.</p>

    <h3>4. Disclaimer</h3>
    <p>The services are provided "as is". We make no warranties regarding the reliability, safety, or performance of the services.</p>

    <h3>5. Changes and Termination</h3>
    <p>We may modify the terms or terminate the service at any time without notice.</p>

    <h2>Privacy Policy</h2>
    <h3>1. Collection and Use of Personal Information</h3>
    <p>We collect information that you provide directly to us, such as when you create or modify your account. We use this information to operate, maintain, and provide the features of the Service.</p>

    <h3>2. Information Sharing</h3>
    <p>We do not share personal information with companies, organizations, or individuals outside of the application except in the following circumstances: with your consent, for legal reasons, or to address fraud and security issues.</p>

    <h3>3. Security</h3>
    <p>We take reasonable measures to help protect information about you from loss, theft, misuse and unauthorized access, disclosure, alteration, and destruction.</p>

    <h3>4. International Users</h3>
    <p>The Service is hosted in the United States and is intended for users located worldwide. If you are a user accessing the Service from the EU, Asia, or any other region with laws governing data collection and use, please note that you are agreeing to the transfer of your personal data to the United States and processing globally.</p>

    <h3>5. Changes to This Policy</h3>
    <p>We may change this privacy policy from time to time. We will post any privacy policy changes on this page and, if the changes are significant, we will provide a more prominent notice.</p>
  </div>
</template>

<script>
export default {
  name: 'TermAndPolicy',
};
</script>

<style>
.terms-policy-container {
  background-color: white;
  padding: 20px;
}
</style>
